.overlayPrevious {
  position: absolute;
  top: 45%;
  left: 20px;
}

.overlayNext {
  position: absolute;
  top: 45%;
  left: calc(100% - 75px);
}

.navLink {
  color: dimgrey;
  text-decoration: none;
}
