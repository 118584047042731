@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i);
body {
  margin: 8px;
  padding: 0;
}

.ArchitecturalStyleInfoField_main__2i4_I {
  padding-bottom: 10px;
  font-weight: lighter;
}

.ArchitecturalStyleInfoField_link__3fHmB {
  color: dimgrey;
  font-weight: normal;
  border-bottom: solid 0.0625rem lightgrey;
  text-decoration: none;
}

.ArchitecturalStyleInfoField_link__3fHmB:hover {
  border-bottom: solid 0.0625rem dimgrey;
}

.ArchitecturalStyleInfoField_label__JiDBR {
  font-weight: bold;
}

.AuthorInfoField_topText__3Qhu4 {
  font-size: 18px;
  font-weight: lighter;
  padding-bottom: 10px;
}

.AuthorInfoField_fieldValue__3Gjfj {
  font-weight: normal;
}

.DataBox_dataContainer__1IVsT {
  float: right;
  font-size: 15px;
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 10px;
  /* width: 45%; */
  max-width: 45%;
}

/* for both mobile and tablet view */
@media only screen and (max-width: 960px) {
  .DataBox_dataContainer__1IVsT {
    float: none;
    max-width: 100%;
    width: 100%;
    margin-left: 0px;
  }
}

.DataBox_addressSection__2H2-T {
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border: 1px dotted grey;
  overflow-wrap: break-word;
  word-wrap: break-word;
  background-color: #f5f5f5;
}

.Footer_footer__1OY8H {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 20px;
  display: block;
  width: 100%;
  overflow: auto;
  color: black;
  box-sizing: border-box;
  background-color: white;
  border-top: 1px solid lightgrey;
}

.Footer_link__1wxKI {
  color: dimgrey;
  font-size: 18px;
  font-weight: 100;
  margin-right: 20px;
  border-bottom: solid 0.0625rem lightgrey;
  text-decoration: none;
}

.Footer_link__1wxKI:hover {
  border-bottom: solid 0.0625rem dimgrey;
}

.ImageCounter_navMenu__2LrpQ {
  width: 60px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 20px;
  font-weight: bold;
}

.ImageGrid_gridContainer__oC7WE {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px;
}

.ImageGrid_gridItem__3XwP9 {
  box-shadow: 5px 5px 10px lightgrey;
  background-color: white;
  position: relative;
  min-height: 50px;
  padding: 5px;
}

.ImageGrid_thumbnail__1BfeM {
  width: 100%;
}

.ImageGrid_link__1Hjhy {
  color: dimgrey;
}

.ImageNavigationLinks_overlayPrevious__2bzZz {
  position: absolute;
  top: 45%;
  left: 20px;
}

.ImageNavigationLinks_overlayNext__3PkBK {
  position: absolute;
  top: 45%;
  left: calc(100% - 75px);
}

.ImageNavigationLinks_navLink__2LQuL {
  color: dimgrey;
  text-decoration: none;
}

.LastUpdateField_topText__3zHGD {
  font-size: 18px;
  font-weight: lighter;
  padding-bottom: 10px;
}

.LastUpdateField_fieldValue__1BS7D {
  font-weight: normal;
}

html {
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial",
    sans-serif;
}

.Layout_page__2_sB2 {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.Layout_mainContent__2Bk8g {
  text-decoration: none;
  color: dimgrey;
  font-size: 18px;

  margin-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.NavBar_link__hxNZ2 {
  color: dimgrey;
  font-size: 18px;
  font-weight: 100;
  text-decoration: none;
}

.NavBar_link__hxNZ2:hover {
  border-bottom: solid 0.0625rem grey;
}

.NavBar_activeLink__1XeMB {
  border-bottom: solid 0.0625rem dimgrey;
}

.NavBar_navbar__14YHx {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.NavBar_navListItem__303Rl {
  display: inline;
  padding: 18px 10px 15px 10px;
}

.NavBar_navListItem__303Rl:hover {
  background-color: #eee;
}

.NavBar_buttonContainer__1yqbf {
  display: none;

  margin-top: 10px;
  margin-bottom: 20px;
}

.NavBar_navMobileButton__2HgM2 {
  border: 1px solid lightgrey;
  text-align: center;
  display: inline-block;
  justify-self: start;
  -webkit-align-self: center;
          align-self: center;
  width: 100%;
  background-color: white;

  text-decoration: none;
  font-size: 18px;
  font-weight: 100;
}

.NavBar_navMobileButton__2HgM2:focus {
  outline: 0;
}

@media only screen and (max-width: 600px) {
  .NavBar_navListItem__303Rl {
    display: block;
    padding: 0px;
    margin-bottom: 10px;
  }

  .NavBar_navbar__14YHx {
    padding-top: 10px;
    padding-bottom: 5px;
    display: none;
  }

  .NavBar_buttonContainer__1yqbf {
    display: block;
  }
}

.NavBar_showNavBar__27Tq8 {
  display: block;
}

.PillsContainer_pillContainer__yp0Si {
  padding-bottom: 10px;
}

.PillsContainer_pill__h8wRn {
  background-color: #ddd;
  border: none;
  color: black;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 10px;
}

.SiteHeader_logoHeader__3jwod {
  padding-bottom: 20px;
}

.SiteHeader_imageLink__3nziJ {
  text-decoration: none;
  width: 100px;
}

.SiteHeader_image__1H6Q- {
  width: 20%;
  min-width: 200px;
  margin-right: 20px;
  margin-bottom: 0px;
}

/* .betaImage {
  height: 80px;
  float: right;
  filter: grayscale(100%);
} */

.SiteHeader_hamburgerContainer__3TInq {
  float: right;
  padding-top: 20px;
  padding-right: 10px;
  display: none;
}

@media only screen and (max-width: 600px) {
  .SiteHeader_hamburgerContainer__3TInq {
    display: block;
  }
}

.StyledLink_link__10_gd {
  color: dimgrey;
  border-bottom: solid 0.0625rem lightgrey;
  text-decoration: none;
}

.StyledLink_link__10_gd:hover {
  border-bottom: solid 0.0625rem dimgrey;
}

.TextInfoField_infoField__20Guu {
  padding-bottom: 10px;
  font-weight: lighter;
}

.TextInfoField_fieldValue__3FoVy {
  font-weight: normal;
}

.TextInfoField_label__1FxIT {
  font-weight: bold;
  margin-bottom: 10px;
}

.TextInfoField_icon__2q2KW {
  padding-right: 5px;
}

.TextInfoWithLinkField_infoField__oLUhv {
  padding-bottom: 20px;
  font-weight: lighter;
}

.TextInfoWithLinkField_fieldValue__2Bwv3 {
  font-weight: normal;
}

.TextInfoWithLinkField_label__1XV5O {
  font-weight: bold;
  margin-bottom: 5px;
}

.TextInfoWithLinkField_icon__1PLGn {
  padding-right: 5px;
}

.TimestampInfoField_topText__3Q30D {
  font-size: 18px;
  font-weight: lighter;
  padding-bottom: 10px;
}

.TimestampInfoField_fieldValue__3UB7d {
  font-weight: normal;
}

.Cinema_topSection__3gPV0 {
  /* margin-bottom: 20px; */
  display: -webkit-flex;
  display: flex;
}

.Cinema_info__2_s0f {
  padding-top: 30px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: right;
  white-space: nowrap;
}

.Cinema_mainContent__2ODA2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.Cinema_leftContainer__IbRJI {
  margin-top: 20px;
  -webkit-flex-basis: 30%;
          flex-basis: 30%;
}

.Cinema_imagesContainer__1VEM- {
}

.Cinema_contentContainer__2A0wo {
  -webkit-flex-basis: 70%;
          flex-basis: 70%;
  padding-left: 40px;
}

.Cinema_cinemaTitle__mADYz {
  margin-bottom: 10px;
}

.Cinema_cityCountryHeader__2n90P {
  margin-top: 0px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 960px) {
  .Cinema_contentContainer__2A0wo {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
  }

  .Cinema_leftContainer__IbRJI {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .Cinema_mainContent__2ODA2 {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .Cinema_contentContainer__2A0wo {
    padding-left: 0px;
  }

  .Cinema_topSection__3gPV0 {
    display: block;
  }

  .Cinema_info__2_s0f {
    padding-top: 0px;
    text-align: left;
  }
}

.Cinema_heroImage__360Y5 {
  margin-bottom: 20px;
  min-height: 100px;
  padding: 10px;
  box-shadow: 5px 5px 20px lightgrey;
}

.Cinema_infoField__3Mm9X {
  padding-bottom: 10px;
  font-weight: lighter;
}

.Cinema_title__1btE7 {
  font-weight: bold;
  -webkit-flex-grow: 3;
          flex-grow: 3;
  padding-right: 20px;
}

.Cinema_fieldValue__1DQEf {
  font-weight: normal;
}

.Cinema_singleImage__2aFD7 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.Cinema_cinemaImage__CHRmd {
  width: 500px;
  height: 500px;
}

.Cinema_description__JlnuH {
  margin-bottom: 40px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.Cinema_description__JlnuH a {
  color: dimgrey;
  border-bottom: solid 0.0625rem lightgrey;
  text-decoration: none;
}

.Cinema_description__JlnuH a:hover {
  border-bottom: solid 0.0625rem dimgrey;
}

.Cinema_link__13n-h {
  color: dimgrey;
}

.Cinema_imageTitle__3KEUO {
  padding-top: 10px;
}

h2 {
  font-weight: 100;
  font-size: 22px;
}

.Cinema_incomplete__3lRfR {
  padding-top: 20px;
  /* padding-right: 20px; */
  /* padding-left: 20px; */
  /* padding-bottom: 20px; */
  margin-bottom: 10px;
  /* border: 1px dotted grey; */
  overflow-wrap: break-word;
  word-wrap: break-word;
  font-style: italic;
  /* background-color: #f5f5f5; */
}
.CinemaImage_infoContainer__UKx-k {
  padding-bottom: 20px;
}

.CinemaImage_aboveImage__WLY42 {
  display: -webkit-flex;
  display: flex;
}

.CinemaImage_image__1tsRN {
  margin-bottom: 20px;
  min-height: 200px;
  max-width: 100%;
  max-height: inherit;
  box-shadow: 5px 5px 20px darkgrey;
  background-color: white;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.CinemaImage_mainImageContainer__2NJZp {
  background-color: #eee;
  margin-bottom: 20px;
  position: relative;
  min-height: 200px;
  max-height: calc(100vh - 300px);
}

.CinemaImage_link__3nlTF {
  color: dimgrey;
  border-bottom: solid 0.0625rem lightgrey;
  text-decoration: none;
}

.CinemaImage_navLink__2E-HE {
  color: dimgrey;
  text-decoration: none;
}

.CinemaImage_link__3nlTF:hover {
  border-bottom: solid 0.0625rem dimgrey;
}

.Home_gridContainer__b1VvZ {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  grid-gap: 20px;
  gap: 20px;
}

.Home_gridItem__aDvjZ {
  box-shadow: 5px 5px 20px lightgrey;
  background-color: white;
  position: relative;
  min-height: 150px;
  padding-bottom: 20px;
}

.Home_gridItem__aDvjZ:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  transition: 0.5s transform linear, 0.5s -webkit-filter linear;
}

.Home_thumbnail__6lLfO {
  width: 100%;
  -webkit-filter: grayscale(25%) brightness(120%) contrast(85%);
          filter: grayscale(25%) brightness(120%) contrast(85%);
  transition: 0.1s filter linear, 0.1s -webkit-filter linear;
}

.Home_thumbnail__6lLfO:hover {
  -webkit-filter: grayscale(0%) brightness(100%) contrast(100%);
          filter: grayscale(0%) brightness(100%) contrast(100%);
  transition: 0.1s filter linear, 0.1s -webkit-filter linear;
}

.Home_title__2tL-t {
  padding: 10px;
  font-size: 18px;
}

.Home_dateField__jCe7x {
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.Home_link__3gU3b {
  text-decoration: none;
  color: dimgrey;
}

.Home_link__3gU3b:hover {
  background-color: lightgrey;
}

.App_page__2WQQC {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.App_logoHeader__27jbs {
  padding-bottom: 20px;
}

.App_navbar__22ZHb {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.App_navListItem__uQLkv {
  display: inline;
  padding: 18px 15px 15px 15px;
}

.App_navListItem__uQLkv:hover {
  background-color: #eee;
}

@media only screen and (max-width: 600px) {
  .App_navListItem__uQLkv {
    display: block;
    padding: 0px;
    margin-bottom: 10px;
  }

  .App_navbar__22ZHb {
    padding-top: 10px;
    padding-bottom: 5px;
  }
}

.App_imageLink__8n1xt {
  text-decoration: none;
  width: 100px;
}

.App_image__3yuZW {
  width: 20%;
  min-width: 200px;
  margin-right: 20px;
  margin-bottom: 0px;
}

.App_betaImage__2_kSp {
  height: 80px;
  float: right;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.App_link__1JHCP {
  text-decoration: none;
  color: dimgrey;
  font-size: 18px;
  font-weight: 100;
}

.App_activeLink__2fPu8 {
  text-decoration: underline;
}

.App_mainContent__2KfwI {
  margin-bottom: 30px;
}

.App_footer__3wsdZ {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 20px;
  display: block;
  width: 100%;
  overflow: auto;
  color: black;
  box-sizing: border-box;
  background-color: white;
  border-top: 1px solid lightgrey;
}

.App_spinner__LXsAW {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 100px;
  padding-bottom: 200px;
}

