.infoField {
  padding-bottom: 20px;
  font-weight: lighter;
}

.fieldValue {
  font-weight: normal;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.icon {
  padding-right: 5px;
}
