.logoHeader {
  padding-bottom: 20px;
}

.imageLink {
  text-decoration: none;
  width: 100px;
}

.image {
  width: 20%;
  min-width: 200px;
  margin-right: 20px;
  margin-bottom: 0px;
}

/* .betaImage {
  height: 80px;
  float: right;
  filter: grayscale(100%);
} */

.hamburgerContainer {
  float: right;
  padding-top: 20px;
  padding-right: 10px;
  display: none;
}

@media only screen and (max-width: 600px) {
  .hamburgerContainer {
    display: block;
  }
}
