.page {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.logoHeader {
  padding-bottom: 20px;
}

.navbar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.navListItem {
  display: inline;
  padding: 18px 15px 15px 15px;
}

.navListItem:hover {
  background-color: #eee;
}

@media only screen and (max-width: 600px) {
  .navListItem {
    display: block;
    padding: 0px;
    margin-bottom: 10px;
  }

  .navbar {
    padding-top: 10px;
    padding-bottom: 5px;
  }
}

.imageLink {
  text-decoration: none;
  width: 100px;
}

.image {
  width: 20%;
  min-width: 200px;
  margin-right: 20px;
  margin-bottom: 0px;
}

.betaImage {
  height: 80px;
  float: right;
  filter: grayscale(100%);
}

.link {
  text-decoration: none;
  color: dimgrey;
  font-size: 18px;
  font-weight: 100;
}

.activeLink {
  text-decoration: underline;
}

.mainContent {
  margin-bottom: 30px;
}

.footer {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 20px;
  display: block;
  width: 100%;
  overflow: auto;
  color: black;
  box-sizing: border-box;
  background-color: white;
  border-top: 1px solid lightgrey;
}

.spinner {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 100px;
  padding-bottom: 200px;
}
