.main {
  padding-bottom: 10px;
  font-weight: lighter;
}

.link {
  color: dimgrey;
  font-weight: normal;
  border-bottom: solid 0.0625rem lightgrey;
  text-decoration: none;
}

.link:hover {
  border-bottom: solid 0.0625rem dimgrey;
}

.label {
  font-weight: bold;
}
