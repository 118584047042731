.link {
  color: dimgrey;
  font-size: 18px;
  font-weight: 100;
  text-decoration: none;
}

.link:hover {
  border-bottom: solid 0.0625rem grey;
}

.activeLink {
  border-bottom: solid 0.0625rem dimgrey;
}

.navbar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.navListItem {
  display: inline;
  padding: 18px 10px 15px 10px;
}

.navListItem:hover {
  background-color: #eee;
}

.buttonContainer {
  display: none;

  margin-top: 10px;
  margin-bottom: 20px;
}

.navMobileButton {
  border: 1px solid lightgrey;
  text-align: center;
  display: inline-block;
  justify-self: start;
  align-self: center;
  width: 100%;
  background-color: white;

  text-decoration: none;
  font-size: 18px;
  font-weight: 100;
}

.navMobileButton:focus {
  outline: 0;
}

@media only screen and (max-width: 600px) {
  .navListItem {
    display: block;
    padding: 0px;
    margin-bottom: 10px;
  }

  .navbar {
    padding-top: 10px;
    padding-bottom: 5px;
    display: none;
  }

  .buttonContainer {
    display: block;
  }
}

.showNavBar {
  display: block;
}
