.topSection {
  /* margin-bottom: 20px; */
  display: flex;
}

.info {
  padding-top: 30px;
  flex-grow: 1;
  text-align: right;
  white-space: nowrap;
}

.mainContent {
  display: flex;
  flex-direction: row;
}

.leftContainer {
  margin-top: 20px;
  flex-basis: 30%;
}

.imagesContainer {
}

.contentContainer {
  flex-basis: 70%;
  padding-left: 40px;
}

.cinemaTitle {
  margin-bottom: 10px;
}

.cityCountryHeader {
  margin-top: 0px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 960px) {
  .contentContainer {
    flex-basis: 50%;
  }

  .leftContainer {
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .mainContent {
    flex-direction: column;
  }

  .contentContainer {
    padding-left: 0px;
  }

  .topSection {
    display: block;
  }

  .info {
    padding-top: 0px;
    text-align: left;
  }
}

.heroImage {
  margin-bottom: 20px;
  min-height: 100px;
  padding: 10px;
  box-shadow: 5px 5px 20px lightgrey;
}

.infoField {
  padding-bottom: 10px;
  font-weight: lighter;
}

.title {
  font-weight: bold;
  flex-grow: 3;
  padding-right: 20px;
}

.fieldValue {
  font-weight: normal;
}

.singleImage {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.cinemaImage {
  width: 500px;
  height: 500px;
}

.description {
  margin-bottom: 40px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.description a {
  color: dimgrey;
  border-bottom: solid 0.0625rem lightgrey;
  text-decoration: none;
}

.description a:hover {
  border-bottom: solid 0.0625rem dimgrey;
}

.link {
  color: dimgrey;
}

.imageTitle {
  padding-top: 10px;
}

h2 {
  font-weight: 100;
  font-size: 22px;
}

.incomplete {
  padding-top: 20px;
  /* padding-right: 20px; */
  /* padding-left: 20px; */
  /* padding-bottom: 20px; */
  margin-bottom: 10px;
  /* border: 1px dotted grey; */
  overflow-wrap: break-word;
  word-wrap: break-word;
  font-style: italic;
  /* background-color: #f5f5f5; */
}