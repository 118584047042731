.infoContainer {
  padding-bottom: 20px;
}

.aboveImage {
  display: flex;
}

.image {
  margin-bottom: 20px;
  min-height: 200px;
  max-width: 100%;
  max-height: inherit;
  box-shadow: 5px 5px 20px darkgrey;
  background-color: white;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.mainImageContainer {
  background-color: #eee;
  margin-bottom: 20px;
  position: relative;
  min-height: 200px;
  max-height: calc(100vh - 300px);
}

.link {
  color: dimgrey;
  border-bottom: solid 0.0625rem lightgrey;
  text-decoration: none;
}

.navLink {
  color: dimgrey;
  text-decoration: none;
}

.link:hover {
  border-bottom: solid 0.0625rem dimgrey;
}
