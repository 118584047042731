.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-bottom: 20px;
}

.gridItem {
  box-shadow: 5px 5px 10px lightgrey;
  background-color: white;
  position: relative;
  min-height: 50px;
  padding: 5px;
}

.thumbnail {
  width: 100%;
}

.link {
  color: dimgrey;
}
