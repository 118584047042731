.infoField {
  padding-bottom: 10px;
  font-weight: lighter;
}

.fieldValue {
  font-weight: normal;
}

.label {
  font-weight: bold;
  margin-bottom: 10px;
}

.icon {
  padding-right: 5px;
}
