.pillContainer {
  padding-bottom: 10px;
}

.pill {
  background-color: #ddd;
  border: none;
  color: black;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 10px;
}
