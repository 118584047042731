.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 20px;
}

.gridItem {
  box-shadow: 5px 5px 20px lightgrey;
  background-color: white;
  position: relative;
  min-height: 150px;
  padding-bottom: 20px;
}

.gridItem:hover {
  transform: scale(1.05);
  transform: translateY(-3px);
  transition: 0.5s transform linear, 0.5s -webkit-filter linear;
}

.thumbnail {
  width: 100%;
  filter: grayscale(25%) brightness(120%) contrast(85%);
  transition: 0.1s filter linear, 0.1s -webkit-filter linear;
}

.thumbnail:hover {
  filter: grayscale(0%) brightness(100%) contrast(100%);
  transition: 0.1s filter linear, 0.1s -webkit-filter linear;
}

.title {
  padding: 10px;
  font-size: 18px;
}

.dateField {
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.link {
  text-decoration: none;
  color: dimgrey;
}

.link:hover {
  background-color: lightgrey;
}
