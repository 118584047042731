@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i");

html {
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial",
    sans-serif;
}

.page {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.mainContent {
  text-decoration: none;
  color: dimgrey;
  font-size: 18px;

  margin-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
