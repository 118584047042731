.dataContainer {
  float: right;
  font-size: 15px;
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 10px;
  /* width: 45%; */
  max-width: 45%;
}

/* for both mobile and tablet view */
@media only screen and (max-width: 960px) {
  .dataContainer {
    float: none;
    max-width: 100%;
    width: 100%;
    margin-left: 0px;
  }
}

.addressSection {
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border: 1px dotted grey;
  overflow-wrap: break-word;
  word-wrap: break-word;
  background-color: #f5f5f5;
}
