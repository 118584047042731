.footer {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 20px;
  display: block;
  width: 100%;
  overflow: auto;
  color: black;
  box-sizing: border-box;
  background-color: white;
  border-top: 1px solid lightgrey;
}

.link {
  color: dimgrey;
  font-size: 18px;
  font-weight: 100;
  margin-right: 20px;
  border-bottom: solid 0.0625rem lightgrey;
  text-decoration: none;
}

.link:hover {
  border-bottom: solid 0.0625rem dimgrey;
}
